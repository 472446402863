import React from 'react';

import './WebsiteList.scss';
import { ListArrow } from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';

let classNames = require('classnames');

const WebsiteList = ({ componentData, imagesArray, alignment }) => {
  const { processUrl, getImage } = useMedias();

  const firstSite = componentData.field_sites_web[0];

  let blocVisuel = false;

  if (firstSite.relationships?.field_image?.drupal_internal__mid != null)
    blocVisuel = true;

  const webSiteList = blocVisuel
    ? componentData.field_sites_web.filter((_, i) => i > 0)
    : componentData.field_sites_web;

  return (
    <div className="websiteList">
      {/* ${alignment === 'right' ? 'alignLeft' : 'alignRight'} */}

      <div className={'websiteListTitle'}>
        <div dangerouslySetInnerHTML={{ __html: componentData?.legend }} />
      </div>

      <div
        className={
          blocVisuel ? 'websiteListDetail has_visuel' : 'websiteListDetail'
        }
      >
        {blocVisuel && (
          <div className={classNames('listVisuel')}>
            {
              firstSite?.field_link?.url ?
                <a target="_blank" href={firstSite?.field_link?.url}>
                  <img
                    src={processUrl(
                      getImage(
                        imagesArray,
                        firstSite.relationships?.field_image?.drupal_internal__mid
                      )?.uri?.url
                    )}
                    alt=""
                  />
                </a>
                :
                <img
                  src={processUrl(
                    getImage(
                      imagesArray,
                      firstSite.relationships?.field_image?.drupal_internal__mid
                    )?.uri?.url
                  )}
                  alt=""
                />
            }

            <ul>
              <li>
                {
                  firstSite?.field_link?.url ?
                    <a target="_blank" href={firstSite?.field_link?.url}>
                      <div className="listTitle">
                        <ListArrow color="color_yellow" aria-hidden="true" />
                        <h4>{firstSite?.field_subtitle}</h4>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: firstSite?.field_text?.processed,
                        }}
                      />
                    </a>
                    :
                    <div>
                      <div className="listTitle">
                        <ListArrow color="color_yellow" aria-hidden="true" />
                        <h4>{firstSite?.field_subtitle}</h4>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: firstSite?.field_text?.processed,
                        }}
                      />
                    </div>

                }

              </li>
            </ul>
          </div>
        )}
        <div className={classNames('listText')}>
          <ul>
            {webSiteList?.map((site, i) => (
              <li key={i}>
                {
                  site?.field_link?.url ?
                    <a target="_blank" href={site?.field_link?.url}>
                      <div className="listTitle">
                        <ListArrow color="color_yellow" aria-hidden="true" />
                        <h4>{site?.field_subtitle}</h4>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: site?.field_text?.processed,
                        }}
                      />
                    </a>
                    :
                    <div>
                      <div className="listTitle">
                        <ListArrow color="color_yellow" aria-hidden="true" />
                        <h4>{site?.field_subtitle}</h4>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: site?.field_text?.processed,
                        }}
                      />
                    </div>
                }

              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WebsiteList;

import React from 'react';

import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import './ContactInfo.scss';

let classNames = require('classnames');

const ContactInfo = ({
  data,
  bgColor
}) => {
  if (!data) { return <></> }

  return (
    <div className='contact_info_block'>
      {
        data?.field_title?.processed &&
        <div className='wrapper_page_xs'>
          <TitleSection
            title={data?.field_title?.processed}
            rtl={data?.field_display_rtl || false}
            h2color={bgColor == 'navy' ? 'white' : ''}
          />
        </div>
      }

      <div className='wrapper_page_xs'>
        <div className={classNames('info_col_list', { 'rtl': data?.field_display_rtl }, { 'color_white': bgColor == "navy" })}>
          {
            data?.field_texte_multiples.map((field_texte, index) => (
              <div key={index} className={data?.field_display_rtl ? 'text-rtl' : ''}>
                <div dangerouslySetInnerHTML={{ __html: field_texte.processed }} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
